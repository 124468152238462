<template>
  <div>
    <div class="messages">
      <message
        v-for="message in $store.getters.messages"
        :key="message.created"
        :message="message"
      ></message>
    </div>
  </div>
</template>
<script>
import Message from "./Message";

export default {
  name: "TemplateApp",
  components: { Message }
};
</script>
<style scoped lang="scss">
.messages {
  position: fixed;
  top: 75px;
  right: 10px;
  z-index: 6;
}
@media all and (max-width: 991px) {
  .messages {
    bottom: 0;
  }
}
</style>
