<script>
import jsonLinksFile from './../../links_config.json';

export default {
  props: {
    show: Boolean,
    config: []
  },
  data: function() {
    if (this.$cookies.isKey("microlearning_cookie_accept") == true)
      this.cookie_get_google_analytics();
    return {
      GoogleAnalytics: this.GoogleAnalytics,
      jsonLinksFile: jsonLinksFile // For all documentation links
    };
  },
  computed: {
    cgu_url() {
      return this.get_url("cgu_url");
    },
    data_url() {
      return this.get_url("data_url");
    },
    cookie_url() {
      return this.get_url("cookie_url");
    }
  },
  methods: {
    cookie_save() {},
    cookie_form(e) {
      if (e.target.name != null) {
        if (e.target.name != "") {
          this.$cookies.config("6m"); // 6 months
          if (e.target.name == "AcceptGoogleAnalytics")
            this.$cookies.set("microlearning_cookie_accept", {
              GoogleAnalytics: "true"
            });
          if (e.target.name == "RefuseGoogleAnalytics")
            this.$cookies.set("microlearning_cookie_accept", {
              GoogleAnalytics: "false"
            });

          this.$parent.cookie_doc_create();
        }
      }
    },
    change_google_analytics: function() {
      if (this.GoogleAnalytics) this.GoogleAnalytics = false;
      else this.GoogleAnalytics = true;
    },
    cookie_get_google_analytics() {
      if (
        (this.$cookies.get("microlearning_cookie_accept").GoogleAnalytics ==
          "true") ==
          true ||
        (this.$cookies.get("microlearning_cookie_accept").GoogleAnalytics ==
          "true") ==
          false
      )
        this.GoogleAnalytics =
          this.$cookies.get("microlearning_cookie_accept").GoogleAnalytics ==
          "true";
    },
    get_url(key) {
      try {
        let keyValue = this.$store.getters.config.layout.url[key];

        if (keyValue.startsWith("jsonLinksFile")) return jsonLinksFile[ keyValue.replace(/^jsonLinksFile:/, '') ].[this.$i18n.locale];
        else return keyValue;
      }
      catch (e) {
        return "";
      }
    }
  },
  watch: {
    show: function() {
      if (this.$cookies.isKey("microlearning_cookie_accept") == true)
        this.cookie_get_google_analytics();
      else {
        this.$cookies.config("6m"); // 6 months
        this.$cookies.set("microlearning_cookie_accept", {
          GoogleAnalytics: "false"
        });

        this.$parent.cookie_doc_create();
      }
    }
  }
};
</script>
<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask cookie-modal">
      <div class="modal-wrapper">
        <form
          v-on:submit.prevent="cookie_save()"
          v-on:click="cookie_form($event)"
          class="modal-container"
        >
          <div class="md-card md-theme-default md-card-login">
            <div class="modal-header">
              <div class="md-card-header md-card-header-green">
                <button
                  type="button"
                  @click="$emit('close')"
                  class="md-button md-simple md-white md-close modal-default-button md-theme-default"
                >
                  <div class="md-button-content modal-close">
                    <i class="material-icons">close</i
                    >{{ $t("cookie.modal.close") }}
                  </div>
                </button>
                <h4 class="card-title">{{ $t("cookie.modal.title") }}</h4>
              </div>
            </div>
            <div class="modal-body">
              <div class="intro">{{ $t("cookie.modal.p-1") }}</div>
              <div class="intro">
                {{ $t("cookie.modal.p-2") }}
                <a class="cookie-link" target="_blank" :href="cookie_url">{{
                  $t("cookie.Cookie")
                }}</a
                >.
              </div>
              <ul>
                <li class="content">
                  <div class="cookie-separator"></div>
                  <div class="cookie-title title">
                    {{ $t("cookie.modal.mandatory") }}
                  </div>
                  <div class="cookie-element">
                    <div class="cookie-col-1">
                      {{ $t("cookie.modal.mandatory-text") }}
                    </div>
                    <div class="cookie-col-2">
                      <button
                        class="cookie-btn"
                        style="background: lightgreen;"
                      >
                        <i class="material-icons">check</i>
                        {{ $t("cookie.modal.accepted") }}
                      </button>
                      <button
                        class="cookie-btn"
                        style="background: transparent;"
                      >
                        <i class="material-icons">check</i>
                        {{ $t("cookie.modal.refuse") }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="content">
                  <div class="cookie-separator"></div>
                  <div class="cookie-title title">
                    {{ $t("cookie.modal.Cookie-1-title") }}
                  </div>
                  <div class="cookie-element">
                    <div class="cookie-col-1">
                      Google Analytics (gtag.js)
                      <br />
                      <a
                        class="cookie-link"
                        target="_blank"
                        :href="cookie_url"
                        >{{ $t("cookie.modal.learn-more") }}</a
                      >
                      -
                      <a
                        class="cookie-link"
                        target="_blank"
                        href="https://policies.google.com/privacy"
                        >{{ $t("cookie.modal.official-website") }}</a
                      >
                    </div>
                    <div class="cookie-col-2">
                      <button
                        name="AcceptGoogleAnalytics"
                        v-on:click="change_google_analytics()"
                        :disabled="GoogleAnalytics"
                        class="cookie-btn"
                        :class="{
                          accept: GoogleAnalytics,
                          'not-selected': !GoogleAnalytics
                        }"
                      >
                        <i class="material-icons">check</i>
                        {{
                          GoogleAnalytics
                            ? $t("cookie.modal.accepted")
                            : $t("cookie.modal.accept")
                        }}
                      </button>
                      <button
                        name="RefuseGoogleAnalytics"
                        v-on:click="change_google_analytics()"
                        :disabled="!GoogleAnalytics"
                        class="cookie-btn"
                        :class="{
                          refuse: !GoogleAnalytics,
                          'not-selected': GoogleAnalytics
                        }"
                      >
                        <i class="material-icons">close</i>
                        {{
                          GoogleAnalytics
                            ? $t("cookie.modal.refuse")
                            : $t("cookie.modal.refused")
                        }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <a class="cookie-link" target="_blank" :href="cgu_url">{{
                $t("cookie.CGU")
              }}</a>
              -
              <a class="cookie-link" target="_blank" :href="data_url">{{
                $t("cookie.DATA")
              }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Transition>
</template>
<style scoped lang="scss">
@import "../assets/scss/cookie.scss";

.modal-container {
  max-width: 750px;
}

.cookie-col-1,
.cookie-col-2 {
  float: left;
  padding: 0 20px 0 20px;
}
.cookie-col-1 {
  width: 65%;
  text-align: left;
}
.cookie-element {
  margin: 10px 20px 10px 20px;
}
.cookie-element:after {
  content: "";
  display: table;
  clear: both;
}
.cookie-separator {
  width: 50%;
  border-top: 1px solid $brand-primary;
  margin: 20px auto 20px auto;
}
.cookie-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 20px 0;
}
.cookie-description {
  text-align: left;
  padding: 10px;
}
.cookie-name {
  font-size: 16px;
  font-weight: bold;
}
.cookie-col-2 {
  width: 35%;
  text-align: right;
}
.cookie-col-2 > button {
  margin-right: 5px;
}
</style>
