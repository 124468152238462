import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import utils from "@/utils";

// Frameworks
import MaterialKit from "@/plugins/material-kit/js/material-kit";

// TODO:: keep only the spinner we like
import "css-spinners/dist/all.min.css";

// Microlearning styling
import "@/assets/scss/microlearning.scss";
import "@/assets/scss/nugget.scss";

import i18n from "@/i18n";
import axios from "axios";
import config from "@/config";
import UniqueId from "vue-unique-id";
import AsyncComputed from "vue-async-computed";

Vue.use(MaterialKit);
Vue.use(UniqueId);
Vue.use(AsyncComputed);
Vue.config.productionTip = false;

// Filters
Vue.filter("truncate", utils.truncate);
Vue.filter("formatDate", utils.formatDate);

// Network
var axios_ = axios.create(config.axios);
axios_.interceptors.request.use(async config => {
  if (store.getters.token)
    config.headers.Authorization = `JWT ${store.getters.token}`;
  // TODO: See why deleting config.headers.Authorization does not work
  else config.headers.Authorization = "";
  return config;
});
Vue.prototype.$axios = axios_;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
