<template>
  <a href="javascript:;" @click="copy"
    ><md-icon style="color: white;">content_copy</md-icon></a
  >
</template>
<script>
export default {
  name: "Clipboard",
  props: ["content"],
  methods: {
    fallback_copy_text_to_clipboard() {
      const text = this.content;
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand("copy");
        this.notify_user();
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      document.body.removeChild(textArea);
    },
    notify_user() {
      this.$store.commit("info", this.$t("content_copied"));
    },
    copy() {
      const text = this.content;
      if (!navigator.clipboard) {
        this.fallback_copy_text_to_clipboard(text);
        return;
      } else
        navigator.clipboard
          .writeText(text)
          .then(this.notify_user, function(err) {
            console.error("Async: Could not copy text: ", err);
          });
    }
  }
};
</script>
