<template>
  <div>
    <div class="md-layout md-alignment-center-center">
      <div
        v-for="partner in partners"
        :key="partner.version_id"
        class="md-layout-item partners"
        :class="partnersClass"
      >
        <router-link
          :to="{
            name: 'Structure',
            params: structure_link_params(partner),
            hash: '#structure_top'
          }"
        >
          <div
            class="partners-list"
            :style="{
              'background-image':
                'url(' + partner.structure_banner_url + '?width=400&height=200)'
            }"
          >
            <div class="partners-black-filter"></div>
            <div class="partners-center">
              <img :src="partner.structure_thumbnail_url" />
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <loading v-if="loading"></loading>

    <div ref="end"></div>
  </div>
</template>
<script>
import Loading from "./Loading";
import partners_list_mixin from "@/mixins/partners_list_mixin";
import utils from "@/utils";
export default {
  name: "PartnersList",
  props: ["structures", "partnersClass"],
  mixins: [partners_list_mixin],
  components: { Loading },
  methods: {
    spy_end_partners() {
      setInterval(
        function() {
          if (
            !this.loading &&
            this.$refs.end &&
            utils.element_in_viewport(this.$refs.end) &&
            (this.pages === undefined || this.page < this.pages)
          )
            this.load_partners();
        }.bind(this),
        40
      );
    }
  }
};
</script>
<style scoped lang="scss">
.partners-list {
  text-align: center;
  position: relative;
  border-radius: 6px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.partners-center {
  position: absolute;
  top: 50%;
  right: 50%;
  -ms-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%);
}
.partners-center img {
  max-height: 75px;
}
.partners-black-filter {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 6px;
}
.md-layout-item.partners {
  min-width: 350px;
  max-width: 350px;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media all and (max-width: 677px) {
  .partners:not(:first-child) {
    margin-top: 25px;
  }
}
</style>
