import moment from "moment";
export default {
  is_iterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  },
  is_empty(value) {
    var empty = false;
    if (value === undefined) empty = true;
    else if (this.is_iterable(value)) {
      if (value.length == 0) empty = true;
    } else if (value == "" || value === undefined || value === null)
      empty = true;
    return empty;
  },
  // https://stackoverflow.com/questions/43335477/how-to-use-setinterval-in-vue-component
  element_in_viewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  },

  /* Copied from https://github.com/SparingSoftware/v-clamp  */
  clamp_element(el) {
    const ellipsisCharacter = "\u2026";
    const lastWordRegex = /[ .,;!?'‘’“”\-–—]*\s(\S)*$/;

    let attempts = 3000;

    // Remove words as long as the text overflows
    while (el.scrollHeight > el.clientHeight) {
      // Remove last word
      el.innerHTML = el.innerHTML.replace(lastWordRegex, ellipsisCharacter);

      // Break if there is no more words to remove, and text still overflows
      if (!/ /.test(el.innerHTML)) {
        el.innerHTML = "";
        break;
      }

      // Just in case...
      if (attempts-- === 0) {
        throw new Error("v-clamp stack overflow!");
      }
    }
  },
  truncate(text, length, suffix) {
    suffix = suffix || "...";
    if (text && text.length > length) {
      return text.substring(0, length) + suffix;
    } else {
      return text;
    }
  },
  // Strips a link of http:// etc
  prettyLink(link) {
    if (!link) return "#";
    const regex = /^(https?:\/\/)?(w{3}\.)?(?<link>.+\.\w+)(\/.*)*$/i;
    let found = link.match(regex);
    let match = found && found.groups.link ? found.groups.link : link;
    return match.toLowerCase();
  },
  // Strips tags from text
  removeTags(str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  },
  // Formatting date values
  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  }
};
