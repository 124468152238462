/* This mixin provides components with helpers for search */
export default {
  data() {
    return {
      search_fulltext: undefined
    };
  },
  watch: {
    $route: function() {
      this.parse_route();
    }
  },
  created() {
    this.parse_route();
  },
  methods: {
    // Parse route into the search_fulltext parameter
    parse_route() {
      if (this.$route.query.fulltext)
        this.search_fulltext = this.$route.query.fulltext;
      else this.search_fulltext = undefined;
    },
    // Hide the software keyboard (when launching search for instance)
    hide_mobile_keyboard() {
      if (
        document.activeElement &&
        document.activeElement.blur &&
        typeof document.activeElement.blur === "function"
      )
        document.activeElement.blur();
    },
    // Navigate to the search page corresponding to the given parameters
    do_search() {
      this.hide_mobile_keyboard();
      this.$router.push(this.search_to);
    }
  },
  computed: {
    // Override this to add custom search parameters
    search_extra_params() {
      return {};
    },
    // Computes the params from fulltext search
    search_fulltext_param() {
      var res = {};
      if (this.search_fulltext) res.fulltext = this.search_fulltext;
      return res;
    },
    // Computes the search params
    search_params() {
      return Object.assign(
        {},
        this.search_fulltext_param,
        this.search_extra_params
      );
    },
    // Computes the location to the search page given the search params
    search_to() {
      let res = {
        name: "Search",
        query: this.search_params
      };
      // Link directly to results on mobile
      if (window.innerWidth < 768 || window.innerHeight <= 720)
        res.hash = "#search_results";
      return res;
    }
  }
};
