import config from "@/config";

// Stores server and client config in a config variable
export default {
  computed: {
    config() {
      return Object.assign({}, config, this.$store.getters.config);
    }
  }
};
