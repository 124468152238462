import Vue from "vue";
import Vuex from "vuex";
import { load_locale_messages } from "@/i18n";

Vue.use(Vuex);

const add_message = function(old_messages, type, text) {
  var messages = old_messages.slice();
  var created = Date.now();
  messages.push({ type, text, created });
  return messages;
};
// get user from local storage
var user = localStorage.getItem("microlearning.user");
try {
  user = JSON.parse(user);
} catch {
  user = undefined;
}
const computeUnfinishedNuggets = state => {
  if (state.completed_nuggets != null && state.experienced_nuggets != null) {
    const res = [];
    state.experienced_nuggets.forEach(nugget_id => {
      if (state.completed_nuggets.indexOf(nugget_id) == -1) {
        res.push(nugget_id);
      }
    });
    state.unfinished_nuggets = res;
  }
  return [];
};

export default new Vuex.Store({
  state: {
    language: "en",
    login_modal: false,
    user,
    messages: [],
    config: undefined,
    version_id: undefined,
    completed_nuggets: null,
    experienced_nuggets: null,
    unfinished_nuggets: null
  },
  mutations: {
    // Register config
    config(state, config) {
      state.config = config;
      load_locale_messages(config.i18n);
    },
    // Shows or hides the login modal
    login_modal(state, showLoginModal) {
      state.login_modal = showLoginModal;
    },
    // Logs user in and stores user information in localstorage
    login(state, user) {
      state.user = user;
      localStorage.setItem("microlearning.user", JSON.stringify(user));
    },
    // Logs user out and dissmisses user information in localstorage
    logout(state) {
      state.user = undefined;
      state.experienced_nuggets = [];
      state.completed_nuggets = [];
      localStorage.removeItem("microlearning.user");
      localStorage.removeItem("user_language");
    },
    // We store the current nugget version id to send on route leave
    version_id(state, version_id) {
      state.version_id = version_id;
    },
    // Adds an inforation message
    info(state, text) {
      state.messages = add_message(state.messages, "primary", text);
    },
    // Adds an error message
    error(state, text) {
      state.messages = add_message(state.messages, "danger", text);
    },
    // Removes a message from the list
    dismiss_message(state, message) {
      for (var i = 0; i < state.messages.length; i++) {
        var state_message = state.messages[i];
        if (state_message.created == message.created) {
          var messages = state.messages.slice();
          messages.splice(i, 1);
          state.messages = messages;
          break;
        }
      }
    },
    // Sets viewed / completed nuggets
    experienced_nuggets(state, data) {
      state.experienced_nuggets = data;
      computeUnfinishedNuggets(state);
    },
    // Adds a viewed / completed nugget
    experienced_nugget(state, nugget_id) {
      if (state.experienced_nuggets != null) {
        state.experienced_nuggets = [...state.experienced_nuggets];
        if (state.experienced_nuggets.indexOf(nugget_id) == -1)
          state.experienced_nuggets.push(nugget_id);
        computeUnfinishedNuggets(state);
      }
    },
    // Sets viewed / completed nuggets
    completed_nuggets(state, data) {
      state.completed_nuggets = data;
      computeUnfinishedNuggets(state);
    },
    // Adds a viewed / completed nugget
    completed_nugget(state, nugget_id) {
      state.completed_nuggets = [...state.completed_nuggets];
      if (state.completed_nuggets.indexOf(nugget_id) == -1)
        state.completed_nuggets.push(nugget_id);
      computeUnfinishedNuggets(state);
    }
  },
  actions: {},
  getters: {
    config(state) {
      return state.config;
    },
    login_modal(state) {
      return state.login_modal;
    },
    authenticated(state) {
      return state.user && state.user.token !== undefined;
    },
    token(state) {
      return state.user ? state.user.token : undefined;
    },
    messages(state) {
      return state.messages;
    },
    version_id(state) {
      return state.version_id;
    },
    get_user(state) {
      if (state.user == null) {
        return null;
      }
      return state.user.email;
    },
    nuggets_status_loaded(state) {
      return (
        state.experienced_nuggets != null && state.completed_nuggets != null
      );
    },
    nuggets_status(state) {
      return {
        experienced: state.experienced_nuggets || [],
        completed: state.completed_nuggets || [],
        unfinished: state.unfinished_nuggets || []
      };
    }
  },
  modules: {}
});
