<template>
  <AppTemplate
    jumbotron-label1="app.name"
    jumbotron-label2="app.caption_1"
    jumbotron-label3="app.caption_2"
    footer-disclaimer="true"
  >
    <div class="main main-raised menu container all_link">
      <div style="float: left;">
        <h4 v-if="$store.getters.authenticated" class="hello_msg resize-mobile">
          {{
            $t("hello_msg", {
              name: $store.state.user.firstname
                ? " " + $store.state.user.firstname
                : ""
            })
          }}
        </h4>
      </div>
      <div style="float: right;">
        <h4 class="resize-mobile">
          <router-link class="navbar-brand" :to="{ name: 'Search' }">
            {{ $t("all_nuggets") }}
          </router-link>
          -
          <router-link class="navbar-brand" :to="{ name: 'Partners' }">
            {{ $t("all_partners") }}
          </router-link>
        </h4>
      </div>
    </div>

    <!-- Public nuggets -->
    <div v-if="!$store.getters.authenticated" id="band_public">
      <div class="main main-raised">
        <nugget-public></nugget-public>
      </div>
    </div>

    <div v-else>
      <!-- Categories -->
      <div
        v-for="(category, index) in categories"
        :id="category.title"
        :key="index"
        class="main main-raised nugget-slider nugget-categories"
        :class="loadedSliders < index ? 'hidden' : ''"
      >
        <nugget-slider
          v-if="loadedSliders >= index"
          :title="category.title"
          :limit="category.limit || 5"
          :center="false"
          :nugget_class="{ 'col-md-3': true }"
          :params="category.params"
          :index="index"
          @ready="ready"
          @failed="ready"
        ></nugget-slider>
        <!-- Loader -->
      </div>
      <div
        class="main main-raised loader"
        v-if="loadedSliders < categories.length"
      >
        <loading></loading>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import NuggetPublic from "@/components/NuggetPublic";
import NuggetSlider from "@/components/NuggetSlider";
import Loading from "@/components/Loading";
import config_mixin from "@/mixins/config_mixin";
import config from "@/config";

export default {
  name: "Home",
  mixins: [config_mixin],
  components: {
    AppTemplate,
    NuggetPublic,
    NuggetSlider,
    Loading
  },
  data() {
    return {
      resizing: null,
      loadedSliders: 0,
      windowWidth: 0
    };
  },
  created() {
    document.title = config.title;
  },
  methods: {
    ready() {
      this.loadedSliders++;
    }
  },
  computed: {
    categories() {
      const categories = this.config.layout
        ? this.config.layout.home.categories
        : [];
      categories.forEach(category => {
        if (category.params_builder) {
          if (category.params_builder == "continue_learning") {
            var nugget_ids = this.$store.getters.nuggets_status.unfinished;
            // Do not display the band if it is empty
            nugget_ids = nugget_ids.slice(0, 15);
            if (nugget_ids.length == 0) {
              nugget_ids = ["dummy"];
            }
            category.params = Object.assign({}, category.params, {
              nugget_ids: nugget_ids
            });
          }
        }
      });
      return categories;
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/nugget_slider.scss";

.hidden {
  opacity: 0;
}
.menu {
  background: none;
  a {
    color: white !important;
  }
}
.loader {
  padding: 100px;
}
.all_link {
  position: absolute;
  margin: -40px 0px 0px 0px;
  padding: 0px 30px 0px 30px;
  box-shadow: none;
}
.hello_msg {
  float: left;
  color: #ffffff;
  -webkit-animation: cssAnimation 5s forwards;
  animation: cssAnimation 5s forwards;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.navbar-brand {
  font-weight: 600;
}

.resize-mobile {
  font-size: 20px;
}

@media screen and (max-width: 991px) {
  .resize-mobile {
    margin-right: 5px;
    font-size: 12px;
  }
}
</style>
