<template>
  <rotating-card
    card-class="md-card-background"
    layer-background
    :front-card-image="cardRotating.cardRotatingBg"
    :manual-rotate-state="localManualRotateState"
    manual-rotate
    style="max-width: 500px"
  >
    <template slot="frontRotateCardContent">
      <div class="front-container" @click="flip_card">
        <div class="nugget-card-name">
          <router-link
            class="card-title-link"
            :to="{
              name: 'NuggetView',
              params: this.nugget_link_params(nugget),
              hash: '#nugget_top'
            }"
            @dragstart.native.prevent
          >
            <h4 class="card-title" :title="nugget.name">
              {{ truncate_title(nugget.name) }}
            </h4>
          </router-link>
        </div>
        <div class="nugget-card-author">
          <p
            v-if="nugget.authorsData && nugget.authorsData.length > 0"
            class="card-title"
          >
            <FullName :person="nugget.authorsData[0]" :link="false"></FullName>
            <span v-if="nugget.authorsData.length > 1">&nbsp;et al.</span>
          </p>
        </div>
        <div class="nugget-card-logo">
          <img
            v-for="(structure_url, index) in nugget.structure_thumbnail_url"
            :key="index"
            alt="Structure logo"
            :src="structure_url"
            @dragstart.prevent
          />
        </div>
      </div>
      <!--Started or Completed icon front-->
      <div class="position">
        <!-- Show icon if viewed or completed -->
        <i class="material-icons green md-48">{{ icon }}</i>
      </div>
    </template>
    <template slot="backRotateCardContent">
      <div class="back-container" @click="flip_card">
        <span>
          <router-link
            class="nugget-detail-link"
            :to="{
              name: 'NuggetDetail',
              params: this.nugget_link_params(nugget),
              hash: '#nugget_top'
            }"
            @dragstart.native.prevent
          >
            <i class="material-icons card-icon">info</i>
          </router-link>
          <router-link
            class="nugget-view-link"
            :to="{
              name: 'NuggetView',
              params: this.nugget_link_params(nugget),
              hash: '#nugget_top'
            }"
            @dragstart.native.prevent
          >
            <i class="material-icons card-icon">play_arrow</i>
          </router-link>
        </span>
        <div class="card-description">
          <div ref="clamp" class="description" v-html="resume"></div>
          <p class="duration text-center">{{ nugget.duration }} MIN</p>
          <p v-show="show_rating" class="rating-wrapper text-center middle">
            <Rated :rate="rate.score" :user="rate.votes" class="small"></Rated>
          </p>
        </div>
      </div>
    </template>
  </rotating-card>
</template>
<script>
import utils from "@/utils";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import RotatingCard from "@/plugins/material-kit/components/cards/RotatingCard";
import FullName from "@/components/FullName";
import Rated from "@/components/Rated";

export default {
  name: "NuggetCard",
  props: ["nugget", "rotate_state"],
  mixins: [microlearning_mixin],
  components: { RotatingCard, FullName, Rated },
  watch: {
    rotate_state(rotate_state) {
      this.localManualRotateState = rotate_state;
    }
  },
  data() {
    return {
      localManualRotateState: false,
      cardRotating: {
        cardRotatingBg: `${this.nugget.nugget_thumbnail_url}?width=350&height=350`
      },
      rate: 0,
      show_rating: false
    };
  },
  created() {
    this.localManualRotateState = this.rotate_state;
  },
  mounted() {
    let clamp = this.$refs.clamp;
    utils.clamp_element(clamp);
  },
  methods: {
    async flip_card() {
      this.localManualRotateState = !this.localManualRotateState;
      this.$emit("rotated", this.localManualRotateState);
      if (this.localManualRotateState) {
        this.rate = await this.get_nugget_average_score(this.nugget.nugget_id);
      }
    },
    truncate_title(title) {
      const words = title.split(" ");
      let truncatedTitle = "";

      for (let i = 0; i < words.length; i++) {
        if ((truncatedTitle + words[i]).length > 40) {
          return truncatedTitle.trim() + " ...";
        }
        truncatedTitle += words[i] + " ";
      }

      return truncatedTitle.trim();
    }
  },
  computed: {
    is_completed() {
      return (
        this.$store.getters.nuggets_status.completed.indexOf(
          this.nugget.nugget_id
        ) != -1
      );
    },
    is_started() {
      return (
        this.$store.getters.nuggets_status.experienced.indexOf(
          this.nugget.nugget_id
        ) != -1
      );
    },
    icon() {
      if (this.is_completed) return "check";
      if (this.is_started) return "visibility";
      return null;
    },
    image_style() {
      return `background-image: url(${this.nugget.nugget_thumbnail_url}?width=350&height=350)`;
    },
    resume() {
      return utils.removeTags(this.nugget.resume);
    }
  }
};
</script>
<style scoped>
.card-description {
  margin: 0px 15px;
  overflow: hidden;
  max-height: 178px;
}
.middle {
  display: inline-block;
  text-align: center;
}
.position {
  width: 20%;
  top: -5%;
  position: relative;
  left: 80%;
}
.duration {
  margin: 10px 0px 2px 0px;
}
.rating-wrapper {
  margin-left: 4px;
}
.material-icons.green {
  color: white;
}
.material-icons-outlined.green {
  color: white;
}
</style>
