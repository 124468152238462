<template>
  <div :class="$route.name.toLowerCase()" id="app_top">
    <!-- Navigation -->
    <AppNav></AppNav>
    <!-- Background -->
    <jumbotron
      :url="jumbotronBackgroundUrl"
      :label1="jumbotronLabel1"
      :label2="jumbotronLabel2"
      :label3="jumbotronLabel3"
      :size="jumbotronSize"
    >
      <slot name="header"></slot>
    </jumbotron>

    <!-- Login Modal -->
    <login></login>

    <!-- Messages -->
    <messages></messages>
    <!-- Content -->
    <div>
      <slot v-on="$listeners"></slot>
    </div>

    <!-- footer -->
    <AppFooter :disclaimer="footerDisclaimer" :config="config"></AppFooter>
    <!-- end footer -->
  </div>
</template>
<script>
import AppNav from "./AppNav";
import AppFooter from "./AppFooter";
import Login from "./Login";
import Jumbotron from "./Jumbotron";
import Messages from "./Messages";

import config_mixin from "@/mixins/config_mixin";

export default {
  name: "AppTemplate",
  components: { AppNav, AppFooter, Login, Jumbotron, Messages },
  mixins: [config_mixin],
  props: [
    "jumbotron-background-url",
    "jumbotron-label-1",
    "jumbotron-label-2",
    "jumbotron-label-3",
    "jumbotron-size",
    "footer-disclaimer"
  ]
};
</script>
<style scoped lang="scss">
.content {
  min-height: 150px;
}
</style>
