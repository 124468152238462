/*!

 =========================================================
 * Vue Material Kit PRO - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/vue-material-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import { directive as vClickOutside } from "vue-clickaway";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", vClickOutside);
  }
};

export default GlobalDirectives;
