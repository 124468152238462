<template>
  <router-link
    :to="{
      name: 'Author',
      params: this.author_link_params(person),
      hash: '#app_top'
    }"
    class="person-fullname"
    :class="{ disabled: !link }"
  >
    <span class="text-capitalize">{{ person.firstname }}&nbsp;</span>
    <span class="text-uppercase">{{ person.lastname }}</span>
  </router-link>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "FullName",
  props: ["person", "link"],
  mixins: [microlearning_mixin]
};
</script>
<style scoped lang="scss">
.disabled {
  pointer-events: none;
}
</style>
