<template>
  <parallax
    class="page-header header-filter clear-filter"
    parallax-active="true"
    :style="url ? `background-image:url(${url});` : ''"
  >
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="image-wrapper">
          <div class="brand">
            <h1>{{ $t(label1) }}</h1>
            <h3 class="title subtitleFont">{{ $t(label2) }}</h3>
            <p class="subsubtitleFont">{{ $t(label3) }}</p>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </parallax>
</template>
<script>
export default {
  name: "Jumbotron",
  props: ["url", "label1", "label2", "label3", "size"]
};
</script>
<style scoped lang="scss">
.brand {
  color: white;
}
.page-header {
  padding-top: 50px;
  height: 550px;
  /* background-image: url($jumbotron); */

  @media screen and (max-width: 768px) {
    height: 300px;
    padding-top: 50px;
  }

  .brand {
    text-align: center;
    position: relative;
    top: -30px;

    h1 {
      font-size: 3.7rem;
      font-weight: bold;
    }
    .subtitleFont {
      font-size: 31px;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .subsubtitleFont {
      font-size: 22px;
      font-weight: 400;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
.search,
.profile,
.partners {
  .page-header {
    height: 350px;

    @media screen and (max-width: 768px), screen and (max-height: 600px) {
      height: 250px;
    }
  }
}
</style>
