<template>
  <AppTemplate
    :jumbotron-background-url="`${nugget.nugget_thumbnail_url}?width=2048`"
    jumbotron-label1=""
    jumbotron-label2=""
    :jumbotron-size="50"
  >
    <div class="main main-raised" id="nugget_top">
      <nugget-nav :nugget="nugget"></nugget-nav>
      <slot></slot>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "./AppTemplate";
import NuggetNav from "./NuggetNav";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import config from "@/config";

export default {
  name: "TemplateNugget",
  components: { AppTemplate, NuggetNav },
  props: ["nugget_id"],
  data() {
    return {
      nugget: {}
    };
  },
  mixins: [microlearning_mixin],
  watch: {
    async nugget_id() {
      this.load();
    }
  },
  created() {
    this.load();
    this.on_login(this.load);
  },
  methods: {
    async load() {
      try {
        this.nugget = await this.get_nugget_with_open_education_information(
          this.nugget_id
        );
      } catch (e) {
        this.access_denied();
      }
      try {
        const promises = [];
        promises.push(this.load_nugget_authors(this.nugget));
        promises.push(this.load_nugget_reviewers(this.nugget));
        promises.push(this.load_nugget_managing_structures(this.nugget));
        promises.push(this.load_nugget_domains(this.nugget));
        promises.push(this.load_nugget_access_licence(this.nugget));
        await Promise.all(promises);
      } catch (e) {
        console.log(e);
      }
      this.nugget = Object.assign({}, this.nugget);

      this.$emit("nugget", this.nugget);

      var authors = [];
      this.nugget["authorsData"].forEach(author => {
        authors.push([author["firstname"], author["lastname"]].join(" "));
      });
      document.title =
        config.title + " - " + this.nugget["name"] + " - " + authors.join(", ");
    }
  }
};
</script>
