<template>
  <div class="container public-nuggets content-band">
    <h2 class="band-title">{{ $t("public_nugget") }}</h2>
    <div class="nugget-list">
      <nugget-list nuggetClass="nugget-container"></nugget-list>
    </div>
  </div>
</template>
<script>
import NuggetList from "@/components/NuggetList.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";

export default {
  name: "NuggetPublic",
  mixins: [microlearning_mixin],
  components: {
    NuggetList
  }
};
</script>
<style scoped lang="scss">
.container.content-band {
  padding-right: 0px;
  padding-left: 0px;
}
.band-title {
  position: relative;
  text-align: center;
  top: 15px;
  margin: 0px;
}
.content-band {
  display: block !important;
  margin-bottom: 30px;
  min-height: 85px;
}
</style>
