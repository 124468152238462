<template>
  <AppTemplate
    jumbotron-label1="app.name"
    jumbotron-label2="auth.sign_up.title"
    footer-disclaimer="true"
  >
    <div class="main main-raised main-narrow">
      <div class="box">
        <h2>{{ $t("auth.sign_up.title") }}</h2>
        <div class="md-layout-item">
          <md-field>
            <label for="email">{{ $t("auth.sign_up.email") }}</label>
            <md-input
              v-model="email"
              id="email"
              name="email"
              ref="email"
              @keydown.enter.native="do_sign_up_email"
            ></md-input>
          </md-field>
          <md-button
            href="javascript:void(0)"
            class="md-success"
            @click="do_sign_up_email"
          >
            {{ $t("auth.sign_up.button") }}
          </md-button>
          <loading v-if="loading"></loading>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import config_mixin from "@/mixins/config_mixin";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import Loading from "@/components/Loading";
import config from "@/config";

export default {
  name: "SignUpEmail",
  mixins: [config_mixin, microlearning_mixin],
  components: {
    AppTemplate,
    Loading
  },
  data() {
    return {
      email: "",
      loading: false
    };
  },
  created() {
    document.title = config.title + " - Sign Up";
  },
  mounted() {
    this.focus_asap(this.$refs.email.$el);
  },
  methods: {
    async do_sign_up_email() {
      this.loading = true;
      if (await this.sign_up_email(this.email)) this.$router.push("/");
      this.loading = false;
    }
  },
  computed: {}
};
</script>
<style scoped lang="scss">
.box {
  padding: 25px;
}
</style>
