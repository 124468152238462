<script>
import jsonLinksFile from './../../links_config.json';

export default {
  props: {
    show: Boolean,
    config: []
  },
  data: function() {
    return {
      jsonLinksFile: jsonLinksFile // For all documentation links
    };
  },
  computed: {
    cgu_url() {
      return this.get_url("cgu_url");
    },
    data_url() {
      return this.get_url("data_url");
    },
    cookie_url() {
      return this.get_url("cookie_url");
    }
  },
  methods: {
    close_modal() {
      if (document.getElementById("validate").checked) {
        this.$parent.cookie_doc_create();
        this.$emit("close");
      } else document.getElementById("checkbox_label").style.color = "red";
    },
    get_url(key) {
      try {
        let keyValue = this.$store.getters.config.layout.url[key];

        if (keyValue.startsWith("jsonLinksFile")) return jsonLinksFile[ keyValue.replace(/^jsonLinksFile:/, '') ].[this.$i18n.locale];
        else return keyValue;
      }
      catch (e) {
        return "";
      }
    }
  }
};
</script>
<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask cookie-modal">
      <div class="modal-wrapper">
        <form class="modal-container">
          <div class="md-card md-theme-default md-card-login">
            <div class="modal-header">
              <div class="md-card-header md-card-header-green">
                <button
                  type="button"
                  @click="close_modal()"
                  class="md-button md-simple md-white md-close modal-default-button md-theme-default"
                >
                  <div class="md-button-content modal-close">
                    <i class="material-icons">close</i>
                    {{ $t("cookie.modal.close") }}
                  </div>
                </button>
                <h4 class="card-title">
                  {{ $t("document.title") }}
                </h4>
              </div>
            </div>
            <div class="modal-body">
              <div class="text">
                {{ $t("document.description") }}
              </div>
              <div class="text">
                <a class="cookie-link" target="_blank" :href="cgu_url">{{
                  $t("document.CGU") + " " + config.cgu_version
                }}</a>
              </div>
              <div class="text">
                <a class="cookie-link" target="_blank" :href="cookie_url">{{
                  $t("document.Cookie") + " " + config.cookie_policy_version
                }}</a>
              </div>
              <div class="text">
                <a class="cookie-link" target="_blank" :href="data_url">{{
                  $t("document.DATA") +
                    " " +
                    config.personal_data_protection_version
                }}</a>
              </div>
            </div>
            <label class="modal-label">
              <input type="checkbox" id="validate" class="modal-checkbox" />
              <span id="checkbox_label">
                {{ $t("document.validate") }}
              </span>
            </label>
          </div>
        </form>
      </div>
    </div>
  </Transition>
</template>
<style scoped lang="scss">
@import "../assets/scss/cookie.scss";

.modal-container {
  max-width: 500px;
}
</style>
