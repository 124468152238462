<template>
  <footer class="footer footer-white footer-big">
    <div v-if="footer && footer.title" class="container">
      <div class="content">
        <div class="md-layout footer-main">
          <div
            class="md-layout-item md-medium-size-25 md-large-size-25 md-xlarge-size-25 md-small-size-100"
          >
            <div id="logo">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="@/assets/img/logo-navbar-color.png"
                  class="nav-logo nav-logo-navbar-color"
                  :alt="$t('app.name')"
                />
              </router-link>
            </div>
            <p>
              {{ $t(footer.description) }}
            </p>
          </div>
          <!-- About -->
          <div
            v-if="footer.about"
            class="md-layout-item md-medium-size-15 md-large-size-15 md-xlarge-size-15 md-small-size-100"
          >
            <h5>{{ $t(footer.about.title) }}</h5>
            <ul class="links-vertical">
              <li v-for="link in footer.about.links" :key="link.text">
                <a :href="get_url(link.href)" target="_blank">
                  {{ $t(link.text) }}
                </a>
              </li>
            </ul>
          </div>
          <!-- About Microlearning -->
          <div
            v-if="footer.about_mlearn"
            class="md-layout-item md-medium-size-15 md-large-size-15 md-xlarge-size-15 md-small-size-100"
          >
            <h5>{{ $t(footer.about_mlearn.title) }}</h5>
            <ul class="links-vertical">
              <li v-for="link in footer.about_mlearn.links" :key="link.text">
                <a :href="get_url(link.href)" target="_blank">
                  {{ $t(link.text) }}
                </a>
              </li>
            </ul>
          </div>
          <!-- Legal -->
          <div
            v-if="footer.legal"
            class="md-layout-item md-medium-size-15 md-large-size-15 md-xlarge-size-15 md-small-size-100"
          >
            <h5>{{ $t(footer.legal.title) }}</h5>
            <ul class="links-vertical">
              <li>
                <a
                  @click="showCookieGestion = true"
                  href="javascript:void(0)"
                  >{{ $t("cookie.manage") }}</a
                >
              </li>
              <li v-for="link in footer.legal.links" :key="link.text">
                <a :href="get_url(link.href)" target="_blank">
                  {{ $t(link.text) }}
                </a>
              </li>
            </ul>
          </div>
          <!-- Subscribe to Newletter -->
          <div
            v-if="footer.newsletter"
            class="md-layout-item md-medium-size-35 md-large-size-35 md-xlarge-size-35 md-small-size-100"
          >
            <h5>{{ $t(footer.newsletter.title) }}</h5>
            <p>
              {{ $t(footer.newsletter.text) }}
            </p>
            <form class="form-newsletter" v-on:submit.prevent="newsletter_sub">
              <div class="md-layout">
                <div class="md-layout-item md-large-size-66 medium-size-50">
                  <md-field>
                    <md-input
                      v-model="email"
                      :placeholder="$t(footer.newsletter.placeholder)"
                    />
                  </md-field>
                </div>
                <div class="md-layout-item md-large-size-33 medium-size-50">
                  <md-button
                    type="submit"
                    class="md-primary md-just-icon md-block"
                  >
                    <md-icon>mail</md-icon>
                  </md-button>
                </div>
              </div>
            </form>
          </div>
          <!-- Associates logos -->
          <div
            v-if="footer.associates"
            class="md-layout-item md-medium-size-100 md-large-size-100 md-xlarge-size-100 md-small-size-100"
          >
            <ul class="logos-horizontal">
              <li v-for="logo in footer.associates.logos" :key="logo.href">
                <a :href="logo.href" target="_blank">
                  <img
                    loading="lazy"
                    :src="logo.src"
                    :alt="logo.alt"
                    width="130"
                    height="130"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr />
      <!-- Social media logos -->
      <ul class="social-buttons" v-if="footer.social">
        <li v-if="footer.social.twitter">
          <md-button
            :href="footer.social.twitter"
            target="_blank"
            class="md-twitter md-just-icon md-simple"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </md-button>
        </li>
        <li v-if="footer.social.facebook">
          <md-button
            :href="footer.social.facebook"
            target="_blank"
            class="md-facebook md-just-icon md-simple"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </md-button>
        </li>
        <li v-if="footer.social.linkedin">
          <md-button
            :href="footer.social.linkedin"
            target="_blank"
            class="md-linkedin md-just-icon md-simple"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" />
          </md-button>
        </li>
        <li v-if="footer.social.instagram">
          <md-button
            :href="footer.social.instagram"
            target="_blank"
            class="md-youtube md-just-icon md-simple"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </md-button>
        </li>
        <li v-if="footer.social.youtube">
          <md-button
            :href="footer.social.youtube"
            target="_blank"
            class="md-youtube md-just-icon md-simple"
          >
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </md-button>
        </li>
        <li v-if="footer.social.public">
          <md-button
            :href="footer.social.public"
            target="_blank"
            class="md-just-icon md-simple"
          >
            <i class="material-icons">public</i>
          </md-button>
        </li>
      </ul>

      <div class="copyright">
        Copyright © {{ year }} {{ $t("owner.name") }} {{ $t("copyright") }}
      </div>
    </div>

    <div to="body">
      <CookieGestion
        :show="showCookieGestion"
        :config="config"
        @close="
          showCookieGestion = false;
          showCookieAlert = !cookie_accept_exist();
        "
      ></CookieGestion>
      <DocVersion
        :show="cookie_doc_exist()"
        :config="config"
        @close="showDocVersion = false"
      ></DocVersion>
    </div>
    <div class="cookie-alert" v-show="showCookieAlert">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-medium-size-75 md-large-size-75 md-xlarge-size-75 md-small-size-100 cookie-alert-description"
          >
            <p>{{ $t("cookie.p-1") }}</p>
            <ul>
              &#8226;
              <a class="cookie-link" target="_blank" :href="cgu_url">{{
                $t("cookie.p-1-1")
              }}</a>
            </ul>
            <ul>
              &#8226;
              <a class="cookie-link" target="_blank" :href="data_url">{{
                $t("cookie.p-1-2")
              }}</a>
            </ul>
            <ul>
              &#8226;
              <a class="cookie-link" target="_blank" :href="cookie_url">{{
                $t("cookie.p-1-3")
              }}</a>
            </ul>
            <p>{{ $t("cookie.p-2") }}</p>
            <ul>
              &#8226;
              {{
                $t("cookie.p-2-1")
              }}
            </ul>
            <p>
              {{ $t("cookie.p-3") }}
              <br />
              {{ $t("cookie.p-4") }}
            </p>
          </div>
          <form
            v-on:submit.prevent="cookie_save()"
            v-on:click="cookie_form($event)"
            class="md-layout-item md-medium-size-25 md-large-size-25 md-xlarge-size-25 md-small-size-100 cookie-alert-button"
          >
            <div>
              <button
                name="AcceptAllCookie"
                class="cookie-btn accept"
                v-on:click="showCookieAlert = !showCookieAlert"
              >
                <i class="material-icons">check</i> {{ $t("cookie.acceptAll") }}
              </button>
            </div>
            <div>
              <button
                name="RefuseAllCookie"
                class="cookie-btn refuse"
                v-on:click="showCookieAlert = !showCookieAlert"
              >
                <i class="material-icons">close</i> {{ $t("cookie.refuseAll") }}
              </button>
            </div>
            <div>
              <a
                @click="showCookieGestion = true"
                class="cookie-link"
                href="javascript:void(0)"
                >{{ $t("cookie.personalize") }}</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </footer>
</template>
<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/vue-cookies@1.8.1/vue-cookies.js"></script>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import CookieGestion from "./CookieGestion.vue";
import DocVersion from "./DocVersionGestion.vue";
import VueCookies from "vue-cookies";

import jsonLinksFile from './../../links_config.json';

// Font Awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTwitter, faFacebook, faLinkedin, faInstagram, faYoutube);

export default {
  name: "FooterApp",
  mixins: [microlearning_mixin],
  components: { FontAwesomeIcon, CookieGestion, DocVersion },
  data() {
    return {
      year: new Date().getFullYear(),
      email: null,
      showCookieGestion: false,
      showCookieAlert: !this.cookie_accept_exist(),
      showDocVersion: this.showDocVersion,
      jsonLinksFile: jsonLinksFile // For all documentation links
    };
  },
  computed: {
    footer() {
      try {
        return this.$store.getters.config.layout.footer;
      } catch (e) {
        return {};
      }
    },
    cgu_url() {
      return this.get_cookie_url("cgu_url");
    },
    data_url() {
      return this.get_cookie_url("data_url");
    },
    cookie_url() {
      return this.get_cookie_url("cookie_url");
    }
  },
  methods: {
    async newsletter_sub() {
      try {
        let res = await this.newsletter(this.email);
        if (res.Count == 1) {
          // Success
          this.$store.commit("info", this.$t(this.footer.newsletter.success));
          this.email = "";
        } else {
          this.$store.commit("error", this.$t(this.footer.newsletter.error));
        }
      } catch (e) {
        var err = document.createElement("html");
        err.innerHTML = e.response.data;
        let ev = err.getElementsByClassName("exception_value")[0];
        var json_ev = JSON.parse(ev.innerText);
        if (json_ev.StatusCode == 400) {
          var message = json_ev.ErrorMessage;
          if (message.substring(0, 4) == "MJ18") {
            // Address already in contact list
            message = this.$t(this.footer.newsletter.already_subscribed);
          } else if (json_ev.ErrorInfo.substring(0, 4) == "MJ08") {
            // Invalid email format
            message = this.$t(this.footer.newsletter.invalid);
          }
          this.$store.commit("error", message);
          return;
        }
        this.$store.commit("error", this.$t(this.footer.newsletter.error));
      }
    },
    cookie_doc_exist() {
      if (this.$cookies.isKey("microlearning_cookie_doc") == true) {
        if (this.config.cgu_version != undefined) {
          if (this.showDocVersion != true) {
            if (
              this.$cookies.get("microlearning_cookie_doc").cgu_version !=
                this.config.cgu_version ||
              this.$cookies.get("microlearning_cookie_doc")
                .cookie_policy_version != this.config.cookie_policy_version ||
              this.$cookies.get("microlearning_cookie_doc")
                .personal_data_protection_version !=
                this.config.personal_data_protection_version
            ) {
              this.showDocVersion = true;
            }
          }
        }
      } else {
        if (this.$cookies.isKey("microlearning_cookie_accept") == true)
          this.showDocVersion = true;
      }

      return this.showDocVersion;
    },
    cookie_save() {},
    cookie_accept_exist() {
      if (this.$cookies.isKey("microlearning_cookie_accept") == true) {
        if (
          (this.$cookies.get("microlearning_cookie_accept").GoogleAnalytics ==
            "true") ==
            true ||
          (this.$cookies.get("microlearning_cookie_accept").GoogleAnalytics ==
            "true") ==
            false
        ) {
          return true;
        }
      }

      return false;
    },
    cookie_form(e) {
      if (e.target.name != null) {
        if (e.target.name != "") {
          this.$cookies.config("6m"); // 6 months
          if (e.target.name == "AcceptAllCookie") {
            this.$cookies.set("microlearning_cookie_accept", {
              GoogleAnalytics: "true"
            });
          }
          if (e.target.name == "RefuseAllCookie") {
            this.$cookies.set("microlearning_cookie_accept", {
              GoogleAnalytics: "false"
            });
          }

          this.cookie_doc_create();
        }
      }
    },
    cookie_doc_create() {
      // version documents juridiques
      this.$cookies.config("6m"); // 6 months
      this.$cookies.set("microlearning_cookie_doc", {
        cgu_version: this.config.cgu_version,
        cookie_policy_version: this.config.cookie_policy_version,
        personal_data_protection_version: this.config
          .personal_data_protection_version
      });
    },
    get_url(key) {
      if (key.startsWith("jsonLinksFile")) return jsonLinksFile[ key.replace(/^jsonLinksFile:/, '') ].[this.$i18n.locale];
      else if (key.startsWith("http")) return key
      else return this.$t(key);
    },
    get_cookie_url(key) {
      try {
        let keyValue = this.$store.getters.config.layout.url[key];
        return this.get_url(keyValue);
      }
      catch (e) {
        return "";
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/cookie.scss";

.footer {
  width: 100%;
  background: white;
  padding-top: 100px;
}
.footer-main {
  justify-content: center;
}
.footer-main #logo {
  margin-bottom: 15px;
}
.footer .links-vertical a {
  color: inherit !important;
}
.logos-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.logos-horizontal a {
  padding: 0px 5px;
}

/* Cookie alert in the page footer */
.cookie-alert {
  position: fixed;
  z-index: 9998;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 -4px 18px 0px rgba(0, 0, 0, 0.12),
    0 -7px 10px -5px rgba(0, 0, 0, 0.15);
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
}
.cookie-alert-description {
  text-align: left;
  font-size: 15px;
}
.cookie-alert-description p {
  margin: 10px 0 0 0;
}
.cookie-alert-description ul {
  margin-left: 15px;
  color: #999999;
}
.cookie-alert-button {
  margin: auto;
}
.cookie-alert-button .accept,
.cookie-alert-button .refuse {
  margin-top: 10px;
  height: 40px;
  width: 100%;
}

@media all and (max-width: 991px) {
  .cookie-alert {
    display: block;
    padding: 10px 20px;
  }

  .cookie-alert-description,
  .cookie-alert-button {
    width: 100%;
  }
}
</style>
