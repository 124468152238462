var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md-layout md-alignment-center-center"},_vm._l((_vm.partners),function(partner){return _c('div',{key:partner.version_id,staticClass:"md-layout-item partners",class:_vm.partnersClass},[_c('router-link',{attrs:{"to":{
          name: 'Structure',
          params: _vm.structure_link_params(partner),
          hash: '#structure_top'
        }}},[_c('div',{staticClass:"partners-list",style:({
            'background-image':
              'url(' + partner.structure_banner_url + '?width=400&height=200)'
          })},[_c('div',{staticClass:"partners-black-filter"}),_c('div',{staticClass:"partners-center"},[_c('img',{attrs:{"src":partner.structure_thumbnail_url}})])])])],1)}),0),(_vm.loading)?_c('loading'):_vm._e(),_c('div',{ref:"end"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }