import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Shibboleth from "../views/Shibboleth.vue";
import Nugget from "../views/Nugget.vue";
import Structure from "../views/Structure.vue";
import Search from "../views/Search.vue";
import Partners from "../views/Partners.vue";
import Author from "../views/Author.vue";
import Profile from "../views/Profile.vue";
import MyNuggets from "../views/MyNuggets.vue";
import SignUp from "../views/SignUp.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";

Vue.use(VueRouter);
Vue.use(require("vue-cookies"));

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/nugget/:slug/detail/:nugget_id",
    name: "NuggetDetail",
    component: Nugget
  },
  {
    path: "/nugget/:slug/view/:nugget_id",
    name: "NuggetView",
    component: Nugget
  },
  {
    path: "/nugget/:slug/share/:nugget_id",
    name: "NuggetShare",
    component: Nugget
  },
  {
    path: "/nugget/:slug/:nugget_id",
    name: "Nugget",
    component: Nugget
  },
  {
    path: "/structure/:slug/:structure_id",
    name: "Structure",
    component: Structure
  },
  {
    path: "/author/:slug/:author_id",
    name: "Author",
    component: Author
  },
  {
    path: "/search",
    name: "Search",
    component: Search
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners
  },
  {
    path: "/shibboleth",
    name: "Shibboleth",
    component: Shibboleth
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/my_nuggets",
    name: "My Nuggets",
    component: MyNuggets
  },
  {
    path: "/sign_up",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/reset_password",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: "/change_password",
    name: "ChangePassword",
    component: ChangePassword
  }
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    setTimeout(() => {
      const element = document.getElementById(to.hash.replace(/#/, ""));
      if (element && element.scrollIntoView)
        element.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);

    //NOTE: This doesn't work for Vue 3
    //return {selector: to.hash}

    //This does
    return { el: to.hash };
  } else if (savedPosition) return savedPosition;
  return { top: 0 };
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
});

export default router;
