<template>
  <div class="loader-wrapper">
    <div class="loader-default spin-skeleton primary"></div>
    <div class="loader-theme"></div>
  </div>
</template>
<script>
export default {
  name: "Loading"
};
</script>
<style scoped lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  padding: 15px;
}
</style>
