/*!

 =========================================================
 * Vue Material Kit PRO - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/vue-material-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

/**
 * You can register global mixins here
 */

const NavbarStore = {
  showNavbar: false
};

const GlobalMixins = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          NavbarStore
        };
      },
      mounted() {
        let { bodyClass } = this.$options;
        if (bodyClass) {
          document.body.classList.add(bodyClass);
        }
      },
      before_destroy() {
        let { bodyClass } = this.$options;
        if (bodyClass) {
          document.body.classList.remove(bodyClass);
        }
      }
    });
  }
};

export default GlobalMixins;
