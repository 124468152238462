/*!

 =========================================================
 * Vue Material Kit PRO - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/vue-material-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

const VerticalNav = {
  methods: {
    scrollToElement(elem) {
      let element_id = document.getElementById(elem);
      if (element_id) {
        element_id.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },
    scrollListener() {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 +
            document.getElementById("main-panel").offsetTop >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  before_destroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};

const Mixins = {
  VerticalNav
};

export default Mixins;
