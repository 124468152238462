/*!

 =========================================================
 * Vue Material Kit PRO - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/vue-material-kit-pro
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import DropDown from "../components/Dropdown";
import Parallax from "../components/Parallax";
import { VPopover } from "v-tooltip";
import MobileMenu from "../layout/MobileMenu";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component(Parallax.name, Parallax);
    Vue.component("v-popover", VPopover);
    Vue.component(MobileMenu.name, MobileMenu);
  }
};

export default GlobalComponents;
