<template>
  <div :class="clazz" role="alert" v-html="message.text"></div>
</template>
<script>
export default {
  name: "TemplateApp",
  props: ["message"],
  mounted() {
    window.setTimeout(() => {
      this.$store.commit("dismiss_message", this.message);
      // TODO: make this duration configurable?
    }, 15000);
  },
  computed: {
    clazz() {
      var key = `alert-${this.message.type}`;
      var res = {
        alert: true
      };
      res[key] = true;
      return res;
    }
  }
};
</script>
<style scoped lang="scss">
a {
  color: white !important;
  text-decoration: underline !important;
}
</style>
