<template>
  <NuggetTemplate
    @nugget="nugget = $event"
    :nugget_id="$route.params.nugget_id"
  >
    <NuggetDetail v-if="this.$route.name === 'NuggetDetail'" :nugget="nugget" />
    <NuggetView v-if="this.$route.name === 'NuggetView'" :nugget="nugget" />
    <NuggetShare v-show="this.$route.name === 'NuggetShare'" :nugget="nugget" />
  </NuggetTemplate>
</template>
<script>
import NuggetTemplate from "@/components/NuggetTemplate.vue";
import NuggetDetail from "@/components/NuggetDetail.vue";
import NuggetView from "@/components/NuggetView.vue";
import NuggetShare from "@/components/NuggetShare.vue";

export default {
  name: "Nugget",
  components: {
    NuggetTemplate,
    NuggetView,
    NuggetDetail,
    NuggetShare
  },
  data() {
    return {
      nugget: {}
    };
  }
};
</script>
