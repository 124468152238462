<template>
  <AppTemplate
    jumbotron-label1="app.name"
    jumbotron-label2="auth.login"
    footer-disclaimer="true"
  >
    <div class="main main-raised">
      <div class="box">
        <h2>{{ $t("auth.shibboleth.title") }}</h2>
        <div class="md-layout-item">
          <md-field>
            <label for="providerId">{{ $t("auth.shibboleth.select") }}</label>
            <md-select
              v-model="entity_id"
              id="providerId"
              name="providerId"
              :placeholder="$t('auth.shibboleth.select')"
              @md-selected="select"
            >
              <md-option
                v-for="institute in institutes"
                :key="institute.name"
                :value="institute.entity_id"
                >{{ institute.name }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import config_mixin from "@/mixins/config_mixin";

export default {
  name: "Shibboleth",
  mixins: [config_mixin],
  components: {
    AppTemplate
  },
  data() {
    return {
      entity_id: undefined
    };
  },
  methods: {
    select() {
      var url = this.config.authentication.shibboleth.discovery_service;
      var target = encodeURIComponent(
        `${this.config.publicUrl}/api/auth_proxy`
      );
      let providerId = encodeURIComponent(this.entity_id);
      url = `${url}?providerId=${providerId}&target=${target}`;
      window.location.href = url;
    }
  },
  computed: {
    institutes() {
      return this.config.authentication
        ? this.config.authentication.shibboleth.identity_providers
        : [];
    },
    form_action() {
      return this.$route.query.return;
    }
  }
};
</script>
<style scoped lang="scss">
.box {
  padding: 25px;
}
</style>
